import Screen from '../components/Screen'
import { PageProps } from 'gatsby'
import { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'

const mercedesBig = ['jsdh/mercedes_2.jpg', 'jsdh/mercedes_1.jpg']
const mercedesSmall = [
  'jsdh/mercedes_vybava_1.jpg',
  'jsdh/mercedes_vybava_2.jpg',
  'jsdh/mercedes_vybava_3.jpg',
  'jsdh/mercedes_vybava_4.jpg',
  'jsdh/mercedes_vybava_5.jpg',
  'jsdh/mercedes_vybava_6.jpg',
]
const fordBig = ['jsdh/IMG_3042.jpeg']
const fordSmall = [
  'jsdh/IMG_3041.jpeg',
  'jsdh/IMG_3043.jpeg',
  'jsdh/IMG_3044.jpeg',
  'jsdh/IMG_3045.jpeg',
  'jsdh/IMG_3046.jpeg',
]

const Jsdh = (props: PageProps) => {
  const [currentImage, setCurrentImage] = useState('')
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = (id: string) => () => {
    setCurrentImage(id)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage('')
    setViewerIsOpen(false)
  }

  return (
    <Screen {...props}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-2/4 lg:pr-12 pt-6">
          <h2 className="h2-medium divider pb-4 mb-4 mt-0">
            Výjezdová jednotka SDH Lipovka
          </h2>
          <p className="p1">
            Jednotka SDH Lipovka - JSDH Rychnov n.Kn. zřizuje město Rychnov nad
            Kněžnou jako svoji Jednotku Požární Ochrany III. kategorie podle
            zákona č.133/1985 sb. o Požární ochraně.
          </p>
          <p className="p1">
            JPO III - vyjíždí do 10 min. po vyhlášení poplachu. Poplach se
            vyhlašuje akustickou sirénou z operačního střediska profesionálních
            hasičů v RK. Na spuštění sirény reaguje systém posílání SMS zpráv,
            na mobily členů výjezdové jednotky, kteří jsou v systému
            přednastaveny. V SMS zprávě dorazí kompletní informace o výjezdu
            (čas, místo, typ události - požár, spadlé stromy atd. ).
          </p>
          <p className="p1">
            V případě požáru v Lipovce, nebo jiné potřeby lze spustit sirénu
            tlačítkem na garáži.
          </p>
        </div>
      </div>
      <p className="p1-medium">
        Podle zákona č.133/1985 o PO musí být JPO III vybavena určenou
        technikou:
      </p>
      <div className="flex flex-col xl:flex-row">
        <div className="xl:w-1/2">
          <h3 className="h3-medium">Mercedes Benz</h3>
          <div className="mb-5 flex flex-col lg:flex-row flex-wrap gap-y-4">
            {mercedesBig.map((photo) => (
              <div
                className="w-full xl:w-2/4 text-center xl:text-auto"
                key={photo}
              >
                <img
                  key={photo}
                  src={photo}
                  className="xl:h-64 shadow-2xl cursor-pointer max-w-full"
                  onClick={openLightbox(photo)}
                />
              </div>
            ))}
          </div>
          <div className="mb-5 flex flex-col xl:flex-row flex-wrap gap-y-4">
            {mercedesSmall.map((photo) => (
              <div
                className="w-full xl:w-2/6 text-center xl:text-auto"
                key={photo}
              >
                <img
                  key={photo}
                  src={photo}
                  className="xl:w-52 shadow-2xl cursor-pointer max-w-full"
                  onClick={openLightbox(photo)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="xl:w-1/2">
          <h3 className="h3-medium">Ford Transit</h3>
          <div className="mb-5 flex flex-col xl:flex-row flex-wrap gap-y-4">
            {fordBig.map((photo) => (
              <div
                className="w-full xl:w-2/4 text-center xl:text-auto"
                key={photo}
              >
                <img
                  key={photo}
                  src={photo}
                  className="xl:h-64 shadow-2xl cursor-pointer max-w-full"
                  onClick={openLightbox(photo)}
                />
              </div>
            ))}
          </div>
          <div className="mb-5 flex flex-col xl:flex-row flex-wrap gap-y-4">
            {fordSmall.map((photo) => (
              <div
                className="w-full xl:w-2/6 text-center xl:text-auto"
                key={photo}
              >
                <img
                  key={photo}
                  src={photo}
                  className="xl:w-52 shadow-2xl cursor-pointer max-w-full"
                  onClick={openLightbox(photo)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <ModalGateway>
        {viewerIsOpen && (
          <Modal onClose={closeLightbox}>
            <Carousel views={[{ source: currentImage }]} />
          </Modal>
        )}
      </ModalGateway>
    </Screen>
  )
}

export default Jsdh
